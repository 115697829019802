import React from 'react';

export default function Projects({ name, duration, description, link, tools }) {

  return (
    <a href={link} target='_blank' className='no-underline text-black'>
        <div className='w-full sm:w-5/6 flex flex-col flex-1 p-3 my-2 hover:shadow-xl hover:rounded-lg hover:bg-white'>

            <div>
                <h4 className='text-sm sm:text-base mb-3'>{duration}</h4>
                <h4 className='text-xl sm:text-2xl'>{name}</h4>
                <h4 className='text-sm px-10'>{description}</h4>
            </div>

            <div className='px-10 py-2 flex-wrap flex flex-row space-x-3'>
                {tools.map((tool) => {
                    return (
                        <h5 className='text-sm text-[#fff] rounded-full bg-[#1fadff] px-2.5 py-2'>{tool}</h5>
                    )
                })}            
            </div>
        
        </div>
    </a>
  )
}
