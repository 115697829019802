import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, BrowserRouter as Router, HashRouter, Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Loading from './components/Loading/Loading';
import { Analytics } from '@vercel/analytics/react';
import { ToastContainer } from 'react-toastify';

function App() {

  // loading is true always
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  // timeout 3 seconds then load page
  useEffect(() => {

    if(firstLoad) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setFirstLoad(false);
    }
  }, []);


  return (

    <div>
      
      <Helmet>
        <meta charSet="utf-8" />
        <title>Timothy Lee</title>
      </Helmet>

      {loading ? (<Loading />) :

        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Home/>} />
          </Routes>
        </BrowserRouter>

      }
      <Analytics mode={'production'} />
      <ToastContainer />
    </div>

  );
}

export default App;
