import React from 'react';
import Experience from '../Experience/Experience';
import Projects from '../Portfolio/Projects';
import { SocialIcon } from 'react-social-icons';
import { HashLink } from 'react-router-hash-link';

export default function Home() {

  return (
    <div className="h-screen flex flex-col sm:flex-row">
      
      <div className="flex-1 flex flex-col sm:flex-row">
        {/* Left Column (mobile scrolls with content) */}
        <div className="w-full sm:w-1/3 bg-stone-200 p-4 flex-none sm:sticky sm:top-0 sm:h-screen">
          <div className="flex flex-col pt-12 sm:px-8 sm:pt-36">
            <div className="mb-4">
              <h1 className="text-[#0085D1] text-2xl">Welcome!</h1>
              <h2 className="text-4xl">I'm Timothy Lee</h2>
            </div>
            <p>
              I'm a recent Lehigh grad, and will be joining JPMorgan Chase & Co. as a Software Engineer this fall! I'm really excited about continuing my education in software and exploring all different avenues, from building on my full-stack and cyber experiences, to learning about other roles like project management and being a product owner.
            </p>

            <div className='pt-4 sm:pt-10'>
              <ul className='list-none flex flex-wrap justify-between space-x-10 sm:flex-col sm:space-y-5 sm:space-x-0'>
                <li className='flex-1 text-[#0085D1] hover:font-bold'>
                  <HashLink smooth to="#top" className='text-[#0085D1] no-underline hover:font-bold'>Experience</HashLink>
                </li>
                <li className='flex-1 text-[#0085D1] hover:font-bold hidden sm:flex'>
                  <HashLink smooth to="" className='text-[#0085D1] no-underline hover:font-bold'>Skills</HashLink>
                </li>
                <li className='flex-1 text-[#0085D1] hover:font-bold'>
                  <HashLink smooth to="/#projects" className='text-[#0085D1] no-underline hover:font-bold'>Projects</HashLink>
                </li>
              </ul>
            </div>


          </div>
        </div>

        {/* Right Column */}
        <div className="flex-1 overflow-auto bg-gray-100 p-4">
          <div className="py-4" id="top">

            <section id="experience">
              <Experience position={'Bachelors in Computer Science and Business'} company={'Lehigh University'} duration={'Graduated May 2024 with Highest Honors'} description={'Relevant Coursework: Analysis of Algorithms, Software Engineering, Programming Languages, Operating Systems Design, Database Systems & Applications. Served as a CSB Mentor, Teachers Assistant, and as co-Founder & Vice President of the Lehigh University Barbell Club.'} link={'https://csb.lehigh.edu/'} />

              <Experience position={'Software Engineering Intern'} company={'JPMorgan Chase & Co.'} duration={'June 2023 - August 2023'} description={'Developed a Windows security application for enployee endpoints that retrieves cybersecurity metrics and performs scans utilizing Powershell, Windows Defender and WinUI3. Participated in an internal global hackathon, and developed the UI in React.js for an AI-powered global search tool which placed 3rd overall in the location.'} />

              <Experience position={'Software Development Intern'} company={'Claudius Legal Intelligence'} duration={'September 2021 - September 2022'} description={'Developed and implemented Figma mockups in Django to overhaul the User Interface, focusing on usability, accessability, and functionality. Developed features across all levels of the Django Model-View-Template architecture.'} link={'https://www.claudius.ai/'} />
            </section>

            <section id="projects">
              <Projects name={'Spotify Profile App'} duration={'April 2023 - August 2023'} description={'Developed a responsive web application that allows users to visualize their Spotify listening data and offers personalized recommendations using the Spotify API. Used Spotifys Authorization Code Flow to securely access a users account information, and deployed the serverless project via Vercel.'} tools={['Javascript', 'React', 'Express', 'Tailwind', 'Vercel']} />

              <Projects name={'Code for Good Hackathon'} duration={'September 2022'} description={'Led frontend development in an Agile team over a 24-hour hackathon. We listened to leaders of a non-profit organization share their concerns with their current product, and developed a full-stack solution to address their major pain points and presented to stakeholders and JPMC employees.'} link={'https://careers.jpmorgan.com/global/en/students/programs/tfsg-hackathons'} tools={['Javascript', 'React', 'Node', 'MongoDB', 'Figma']} />

              <Projects name={'Chase that Pump Website'} duration={'June 2021 - January 2022'} description={'Developed an online workout platform with 40+ manually selected workouts with detailed tutorials, videos and workout plans. My inspiration came as a beginner in the gym as I wanted for a central platform where I could find any workout by specifying which muscle groups I wanted to target and being able to get a quick lesson.'} tools={['Python', 'Django', 'PostgreSQL', 'AWS S3', 'Heroku']} />
            </section>

          </div>
        </div>
      </div>
    </div>
  )
}
