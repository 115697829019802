import React from 'react';

export default function Experience({ position, company, duration, description, link }) {
  return (
    <a href={link} target='_blank' className='no-underline text-black'>

    
    <div className='w-full sm:w-5/6 flex flex-1 flex-col p-3 my-2 hover:shadow-xl hover:rounded-lg hover:bg-neutral-100'>

        <div>
            <h4 className='text-sm mb-3 sm:text-base'>{duration}</h4>
            <h4 className='text-xl sm:text-2xl'>{position}</h4>
            <h4 className='text-md sm:text-lg'>{company}</h4>            
            <h4 className='text-sm px-10'>{description}</h4>    
        </div>
        
    </div>

    </a>
  )
}
