import React from 'react';
import { RingLoader } from 'react-spinners';
import { TypeAnimation } from 'react-type-animation';


export default function Loading() {
  return (
    <div className='h-screen flex flex-col justify-center items-center m-0'>
          <RingLoader 
            loading={true}
            color='#0085D1'
            size={80}
          />
          <TypeAnimation
            sequence={[
              'Timothy Lee Portfolio', 
              1000,
            ]}
            wrapper="h3"
            cursor={true}
            speed={38}
            style={{ fontSize: '2em', padding: '30px' }}
          />
    </div>
  )
}
